import React from 'react';
import { Box, Button, Flex, Heading, Stack, Text, useColorModeValue as mode } from '@chakra-ui/react';
import Link from 'next/link';

export const HeroCard = React.memo(() => {
  return (
    <Box as='section' pt={{ base: 0, md: 24 }} overflow='hidden'>
      <Box mx='auto' px={{ base: '6', md: '8' }}>
        <Flex align='flex-start' direction={{ base: 'column', lg: 'row' }} justify='space-between' mb='20'>
          <Box flex='1' maxW={{ lg: 'xl' }} pt='6'>
            <Heading as='h1' size='3xl' mt='8' fontWeight='extrabold'>
              Seamlessly Connect CSV Data to Any API
            </Heading>
            <Text color={mode('gray.600', 'gray.400')} mt='5' fontSize='xl'>
              Stop spending weeks on custom integrations. Connect your CSV files to any REST API in minutes.
            </Text>
            <Stack spacing={6} direction={{ base: 'column', md: 'row' }}>
              <Link href='/sign-up/'>
                <Button
                  mt='8'
                  minW='14rem'
                  colorScheme='orange'
                  size='lg'
                  height='14'
                  px='8'
                  fontSize='md'
                  fontWeight='bold'
                  bgColor='secondary.400'
                >
                  Get started!
                </Button>
              </Link>
              <Text size='xs' pt={{ base: 0, md: 12, lg: 8 }} verticalAlign='middle'>
                More than 1 million lines processed and counting!
              </Text>
            </Stack>
          </Box>
          <Box boxSize={{ base: '20', lg: '8' }} />
        </Flex>
      </Box>
    </Box>
  );
});

HeroCard.displayName = 'HeroCard';
