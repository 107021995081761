import { Button, Container, Flex, Heading, Icon, Link, Stack, Text } from '@chakra-ui/react';
import { LuChevronRight, LuKeyRound, LuUser } from 'react-icons/lu';
import NextLink from 'next/link';

const features = [
  {
    icon: <LuKeyRound />,
    title: 'Sign up',
    description: 'Sign up for a free account and start migrating today',
    url: '/sign-up/',
    action: 'Sign up now'
  },
  {
    icon: <LuUser />,
    title: 'Book a Demo',
    description: 'Speak with our team to learn more about our platform',
    // eslint-disable-next-line max-len
    url: 'mailto:info@smartparse.io?subject=We%20are%20interested%20in%20a%20demo&body=Hello%20SmartParse%20team%2C%0A%0AI%20am%20interested%20in%20learning%20more%20about%20your%20CSV-to-API%20solution.%20Could%20you%20please%20schedule%20a%20demo%20for%20me%3F%0A%0AThank%20you%2C%0A%5BYour%20Name%5D',
    action: 'Book a Demo'
  }
];

const CTA = () => {
  return (
    <Container maxW='6xl' py='20' my={10} bg='bg-surface-landing' p={{ base: 10, md: 10 }} w='full' borderRadius='2xl'>
      <Flex direction={{ base: 'column', md: 'row' }} gap={{ base: '8', md: '20' }} justify='space-between'>
        <Stack flex='1' maxW='2xl' align='flex-start'>
          <Heading as='h2' size='sm'>
            Ready to Simplify Your CSV-to-API Pipeline?
          </Heading>
          <Text color='fg.muted' textStyle='lg' mb='4'>
            Join teams who&apos;ve cut their data migration time by 120x
          </Text>
          <Button as={NextLink} href='/sign-up/' size='lg'>
            Start Processing
          </Button>
        </Stack>

        <Flex direction={{ base: 'column', md: 'row' }} flex='1' gap={{ base: '2', md: '4' }} align='flex-start'>
          {features.map((feature) => (
            <Stack direction={{ base: 'row', md: 'column' }} key={feature.title} gap={2} py={4}>
              <Icon color='colorPalette.solid' boxSize={10}>
                {feature.icon}
              </Icon>
              <Stack flex='1'>
                <Text fontWeight='medium'>{feature.title}</Text>
                <Text color='fg.muted' textStyle='sm'>
                  {feature.description}
                </Text>
                <Link
                  as={NextLink}
                  href={feature.url}
                  fontWeight='medium'
                  textStyle='sm'
                  display='flex'
                  alignItems='center'
                  _hover={{ textDecoration: 'none' }}
                >
                  {feature.action}
                  <Icon as={LuChevronRight} color='fg.muted' ml={1} boxSize={4} />
                </Link>
              </Stack>
            </Stack>
          ))}
        </Flex>
      </Flex>
    </Container>
  );
};

export default CTA;
