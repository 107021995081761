import React from 'react';
import { Box, BoxProps, Button, Flex, Heading, Text, VStack } from '@chakra-ui/react';
import NextLink from 'next/link';
import { ArrowForwardIcon } from '@chakra-ui/icons';
import { getFloatAnimation } from '../../libs/utils';
import FeatureIcon from './FeatureIcon';

const PainPoints = React.memo(({ ...props }: BoxProps) => {
  return (
    <VStack spacing={6} alignItems='flex-start' width='100%' px={{ base: 2, md: 12 }}>
      <Box py={{ base: 0, md: 12 }} maxW='2xl' mx='auto' textAlign='center'>
        <Heading as='h2' fontWeight='bolder'>
          Sound Familiar?
        </Heading>
        <Text as='h3' size='sm' fontSize='lg' color='blackAlpha.800' _dark={{ color: 'brand.50' }}>
          Don&apos;t let CSV integration slow down your development. Here&apos;s what our customers were struggling with
          before SmartParse.
        </Text>
      </Box>

      <Flex
        as='section'
        align='flex-start'
        direction={{ base: 'column', lg: 'row' }}
        justify='space-between'
        {...props}
      >
        <VStack w='100%' spacing={4}>
          <Flex flexWrap='wrap' justifyContent='center' w='100%' gap={6}>
            {[
              {
                title: 'Custom Script Overload',
                icon: 'scale',
                description:
                  'Spending precious development time writing and maintaining custom scripts just to format CSV data for your APIs.'
              },
              {
                title: 'Rate Limit Headaches',
                icon: 'quick',
                description:
                  'Constantly dealing with API rate limits and failed uploads, requiring complex retry logic and error handling.'
              },
              {
                title: 'SFTP Servers',
                icon: 'sftp',
                description:
                  'Managing and maintaining SFTP servers solely for file transfers, adding unnecessary infrastructure overhead.'
              },
              {
                title: 'Line-by-Line Debugging',
                icon: 'no code',
                description:
                  'Hours spent debugging data transformation errors line by line, slowing down your entire development pipeline.'
              },
              {
                title: 'Resource Drain',
                icon: 'reports',
                description:
                  'Valuable developer time spent on data migration tasks instead of building core features and improvements.'
              }
            ].map((item, index) => (
              <Box
                key={index}
                px={{ base: '4', md: '6' }}
                py={{ base: '5', md: '6' }}
                bg='bg-surface-landing'
                borderRadius='lg'
                animation={getFloatAnimation()}
                width={{ base: '100%', md: '48%', lg: '32%' }}
                mb={4}
              >
                <Flex justifyContent='space-between' w='100%'>
                  <Heading as='h3' size='xs' mb={4}>
                    {item.title}
                  </Heading>
                  <FeatureIcon
                    variant={item.icon as 'scale' | 'quick' | 'sftp' | 'no code' | 'reports' | 'flatfile'}
                    color='bg-default-inverted'
                  />
                </Flex>
                <Text color='bg-default-inverted'>{item.description}</Text>
              </Box>
            ))}
          </Flex>
          <Button as={NextLink} href='/sign-up/' size='xl' mt={4} rightIcon={<ArrowForwardIcon />} bgColor='brand.200'>
            Solve these today
          </Button>
        </VStack>
      </Flex>
    </VStack>
  );
});

export default PainPoints;
