import { Box, Grid, GridItem, Heading, Img, Text, VStack } from '@chakra-ui/react';
import React from 'react';

export const MappingTools = () => (
  <VStack spacing={6} alignItems='flex-start' width='100%' px={{ base: 2, md: 12 }}>
    <Box py={{ base: 0, md: 12 }}>
      <Heading as='h2' fontWeight='bolder'>
        Powerful Mapping Tools
      </Heading>
      <Heading as='h3' size='sm' fontSize='lg' color='blackAlpha.600' _dark={{ color: 'whiteAlpha.600' }}>
        Easy to use CSV mapping editor and previews
      </Heading>
    </Box>

    <Grid templateColumns={{ base: 'repeat(1, 1fr)', lg: 'repeat(2, 1fr)' }} gap={10} alignItems='top'>
      <GridItem order={{ base: 1, lg: 1 }}>
        <Heading as='h4' size='lg' fontSize='2xl'>
          Upload a data sample to get started
        </Heading>
        <Text color='blackAlpha.600' _dark={{ color: 'whiteAlpha.700' }}>
          Upload a few sample lines to validate your data and structure, preview them in a tabular format, and provide
          contextual feedback as you map.
        </Text>
      </GridItem>
      <GridItem pb={{ base: 0, lg: 10 }} order={{ base: 2, lg: 0 }}>
        <Img
          src='/images/mapping/mapping-csv.gif'
          alt='CSV mapping'
          borderRadius='lg'
          // maxW='80%'
          bgColor='#1a1d26'
          p={4}
          loading='lazy'
          objectFit='cover'
        />
      </GridItem>

      <GridItem>
        <Heading as='h4' size='lg' fontSize='2xl'>
          Intuitive mapping using your data
        </Heading>
        <Text color='blackAlpha.600' _dark={{ color: 'whiteAlpha.700' }}>
          As you type, you&apos;ll receive contextual suggestions tailored to your data. Enjoy a real-time preview of
          your output, allowing you to explore different variations, one line at a time.
        </Text>
      </GridItem>
      <GridItem pb={{ base: 0, lg: 10 }}>
        <Img
          src='/images/mapping/mapping-simple.gif'
          alt='CSV mapping - Simple'
          borderRadius='lg'
          // maxW='80%'
          bgColor='#1a1d26'
          p={4}
          loading='lazy'
          objectFit='cover'
        />
      </GridItem>

      <GridItem>
        <Heading as='h4' size='lg' fontSize='2xl'>
          Utility and Helper Functions
        </Heading>
        <Text color='blackAlpha.600' _dark={{ color: 'whiteAlpha.700' }}>
          A host of utility and helper functions help you quickly navigate complex mappings with casting types,
          conditionals, object groups, and array roll-ups. Creating and maintaining mappings has never been easier.
        </Text>
      </GridItem>
      <GridItem pb={{ base: 0, lg: 10 }}>
        <Img
          src='/images/mapping/mapping-complex.gif'
          alt='CSV mapping - Complex'
          borderRadius='lg'
          // maxW='80%'
          bgColor='#1a1d26'
          p={4}
          loading='lazy'
          objectFit='cover'
        />
      </GridItem>
    </Grid>
  </VStack>
);
