/* eslint-disable max-len */
import React, { FC } from 'react';
import { Box, Heading, HStack, List, ListItem, Text, useColorModeValue } from '@chakra-ui/react';
import { FAQPageJsonLd } from 'next-seo';

interface FAQsProps {}

const FAQs: FC<FAQsProps> = React.memo(() => {
  const faqs = [
    {
      question: 'How quickly can we get started?',
      answer:
        'You can start sending data to your API endpoints in minutes. Our mapping interface is intuitive and easy to use.'
    },
    {
      question: 'What types of APIs do you support?',
      answer:
        'Any REST API endpoint that accepts JSON payloads. This includes popular platforms like Salesforce, Shopify, and custom internal APIs.'
    },
    {
      question: 'Do you create APIs from CSV files?',
      answer:
        'No, SmartParse connects your CSV data to your existing API endpoints. We handle the transformation and delivery of your data to any REST API.'
    },
    {
      question: 'What happens if my API goes down during processing?',
      answer:
        "SmartParse includes circuit breakers that prevent API flooding during downtime, and data quarantining to isolate failed records. You can configure rate limits to match your API's requirements and easily reprocess quarantined data once your API is back online."
    }
  ];
  return (
    <Box id='faqs' mb={20}>
      <FAQPageJsonLd
        mainEntity={faqs.map((faq) => ({
          questionName: faq.question,
          acceptedAnswerText: faq.answer
        }))}
      />

      <Heading as='h2' size='3xl' mb={12} fontWeight='extrabold' textAlign='center'>
        Common Questions
      </Heading>
      <Box>
        <List spacing={6} fontSize='lg' maxW='2xl' mx='auto'>
          {faqs.map((faq) => (
            <ListItem key={faq.question}>
              <HStack spacing={2} mb={0}>
                <Heading as='h4' size='xs' fontSize='lg' fontWeight='bold'>
                  Q: {faq.question}
                </Heading>
              </HStack>
              <Text fontSize='lg' color={useColorModeValue('gray.800', 'gray.300')}>
                {faq.answer}
              </Text>
            </ListItem>
          ))}
        </List>
      </Box>
    </Box>
  );
});

FAQs.displayName = 'FAQs';
export default FAQs;
